import useSearchFilter from "@src/shared/context/searchfilter/useSearchFilter";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { GQLLeaseMe_DocumentFacet } from "src/graphql.gatsby.generated";

import { ButtonText, DesktopList, ExpandButton, LinkArrow, LinkItem, MobileList, ShowLessChevron, ShowMoreChevron } from "./RelatedLinksList.sc";

interface RelatedLinksListProps {
    itemList: Pick<GQLLeaseMe_DocumentFacet, "name" | "slug" | "count">[] | undefined;
    itemType: "modelGroups" | "brands" | "bodyTypes" | "provinces";
}

const RelatedLinksList: React.FunctionComponent<RelatedLinksListProps> = ({ itemList, itemType }) => {
    const { filter, generateUrlFromFilter } = useSearchFilter();

    const [sliceMobileCount, setSliceMobileCount] = React.useState(3);
    const [isExpanded, setIsExpanded] = React.useState(false);
    const handleExpandableButton = () => {
        isExpanded ? setSliceMobileCount(3) : setSliceMobileCount(10);
        setIsExpanded(!isExpanded);
    };

    const getLinkItem = (item: any) => {
        let filterProps: { [x: string]: any } = {
            [itemType]: [item.slug],
        };

        if (itemType === "modelGroups") filterProps.brands = item.brandSlug;
        if (itemType === "brands") filterProps = { brands: item.slug };

        return (
            <LinkItem
                to={generateUrlFromFilter(
                    {
                        ...filter,
                        ...filterProps,
                    },
                    "vehicles",
                )}
                key={item.slug}
                onClick={scrollTop}
            >
                <LinkArrow />
                {item.name}
            </LinkItem>
        );
    };

    return (
        <>
            <DesktopList>
                {itemList?.map((item) => {
                    return getLinkItem(item);
                })}
            </DesktopList>
            <MobileList>
                {itemList?.slice(0, sliceMobileCount).map((item) => {
                    return getLinkItem(item);
                })}
                {itemList && itemList.length > 3 && (
                    <>
                        {isExpanded ? (
                            <ExpandButton>
                                <ButtonText onClick={handleExpandableButton} data-gtm-event="related-links" data-gtm-event-details="Modelle">
                                    <ShowLessChevron />
                                    <FormattedMessage id="leaseme.showLess" defaultMessage="Weniger anzeigen" />
                                </ButtonText>
                            </ExpandButton>
                        ) : (
                            <ExpandButton>
                                <ButtonText onClick={handleExpandableButton} data-gtm-event="related-links" data-gtm-event-details="Modelle">
                                    <ShowMoreChevron />
                                    <FormattedMessage id="leaseme.showMore" defaultMessage="Mehr anzeigen" />
                                </ButtonText>
                            </ExpandButton>
                        )}
                    </>
                )}
            </MobileList>
        </>
    );
};

export default RelatedLinksList;

function scrollTop() {
    window.scrollTo(0, 0);
}
