import { getConfig } from "@src/config";
import Button, { ButtonSize, ButtonVariant, ButtonWidth } from "@src/shared/button/Button";
import useSearchFilter from "@src/shared/context/searchfilter/useSearchFilter";
import useSearchResult from "@src/shared/context/searchresult/useSearchResult";
import LoadingSpinner from "@src/shared/loadingSpinner/LoadingSpinner";
import SliderFilter from "@src/shared/searchResults/searchfilter/filter/sliderfilter/SliderFilter";
import { sliderMinMaxValues } from "@src/shared/searchResults/searchfilter/filter/sliderfilter/sliderMinMaxValues";
import { getCurrencySignFromDomain } from "@src/skeleton/intlProvider/CustomIntlProvider";
import React from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";

import { ButtonFilterContainer, LinkArrow, LoadingSpinnerWrapper, ResultsLink, SliderContainer, TabContent } from "./BoxTabContent.sc";

interface BoxTabContentProps {
    hasEndAdornment?: boolean;
    infoIconText?: React.ReactNode;
}

const BoxTabContent: React.FC<BoxTabContentProps> = ({ hasEndAdornment, infoIconText }) => {
    const intl = useIntl();
    const filter = useSearchFilter();
    const searchResult = useSearchResult();

    const sliderValues = sliderMinMaxValues(getConfig("scope").domain);

    return (
        <TabContent>
            <SliderContainer>
                <SliderFilter
                    value={filter.filter.maximumRate}
                    minValue={sliderValues.min}
                    maxValue={sliderValues.max}
                    onChange={(value) => filter.setFilter?.("maximumRate", value)}
                    captionStyling={getConfig("isCarhubActive") && getConfig("scope").domain === "lease-me.porschebank.at" ? "column" : "row"}
                    currencySign={getCurrencySignFromDomain(getConfig("scope").domain)}
                    hasEndAdornment={hasEndAdornment}
                    infoIconText={infoIconText}
                />
                {getConfig("isCarhubActive") && getConfig("scope").domain === "lease-me.porschebank.at" && (
                    <ButtonFilterContainer>
                        <Button
                            variant={filter.filter.carType === "usedCar" ? ButtonVariant.Primary : ButtonVariant.Secondary}
                            width={ButtonWidth.FullWidth}
                            size={ButtonSize.Default}
                            onClick={() => filter.setFilter?.("carType", "usedCar")}
                        >
                            {intl.formatMessage({ id: "leasme.filter.carType.usedCar", defaultMessage: "Gebrauchtwagen" })}
                        </Button>
                        <Button
                            variant={filter.filter.carType === "newCar" ? ButtonVariant.Primary : ButtonVariant.Secondary}
                            width={ButtonWidth.FullWidth}
                            size={ButtonSize.Default}
                            onClick={() => filter.setFilter?.("carType", "newCar")}
                        >
                            {intl.formatMessage({ id: "leasme.filter.carType.newCar", defaultMessage: "Neuwagen" })}
                        </Button>
                    </ButtonFilterContainer>
                )}
            </SliderContainer>
            <ResultsLink to={filter.generateUrlFromFilter(filter.filter, "vehicles")}>
                {searchResult.loading && (
                    <LoadingSpinnerWrapper>
                        <LoadingSpinner />
                    </LoadingSpinnerWrapper>
                )}
                {!searchResult.loading && (
                    <>
                        <div>
                            <LinkArrow />
                        </div>
                        <FormattedNumber value={searchResult.totalCount} />{" "}
                        {getConfig("isCarhubActive") && getConfig("scope").domain === "lease-me.porschebank.at" ? (
                            <FormattedMessage
                                id="leaseme.results"
                                defaultMessage="{total, plural, =1 {{single}} other {{plural}}}"
                                values={{
                                    total: searchResult.totalCount,
                                    single: intl.formatMessage({
                                        id: "leaseme.results.slider.single",
                                        defaultMessage: "Treffer anzeigen",
                                    }),
                                    plural: intl.formatMessage({
                                        id: "leaseme.results.slider.plural",
                                        defaultMessage: "Treffer anzeigen",
                                    }),
                                }}
                            />
                        ) : (
                            <FormattedMessage
                                id="leaseme.results"
                                defaultMessage="{total, plural, =1 {{single}} other {{plural}}}"
                                values={{
                                    total: searchResult.totalCount,
                                    single: intl.formatMessage({
                                        id: "leaseme.results.single",
                                        defaultMessage: "Treffer",
                                    }),
                                    plural: intl.formatMessage({
                                        id: "leaseme.results.plural",
                                        defaultMessage: "Treffer",
                                    }),
                                }}
                            />
                        )}
                    </>
                )}
            </ResultsLink>
        </TabContent>
    );
};

export default BoxTabContent;
