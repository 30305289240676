import { getConfig } from "@src/config";
import { GQLFinanceType } from "@src/graphql.apollo.generated";
import useSearchFilter from "@src/shared/context/searchfilter/useSearchFilter";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import { Tab, Tabs } from "./BoxTabs.sc";

const BoxTabs: React.FC = () => {
    const scope = getConfig("scope");
    const filter = useSearchFilter();
    const [financeType, setFinanceType] = useState<
        GQLFinanceType.leasing | GQLFinanceType.credit | GQLFinanceType.openEndLeasing | GQLFinanceType.closedEndLeasing
    >();

    React.useEffect(() => {
        setFinanceType(filter.filter.financeType);
    }, [filter.filter.financeType]);

    const handleOnClick = (type: GQLFinanceType) => {
        filter.setFilter?.("financeType", type);
        setFinanceType(type);
    };

    return (
        <Tabs>
            {scope.domain !== "car4me.porschefinance.hu" ? (
                <>
                    <Tab onClick={() => handleOnClick(GQLFinanceType.leasing)} disabled={financeType === GQLFinanceType.leasing}>
                        <FormattedMessage id="leaseme.leasing" defaultMessage="Leasing" />
                    </Tab>
                    {getConfig("hasCredit") && (
                        <>
                            <Tab onClick={() => handleOnClick(GQLFinanceType.credit)} disabled={financeType === GQLFinanceType.credit}>
                                <FormattedMessage id="leaseme.credit" defaultMessage="Kredit" />
                            </Tab>
                        </>
                    )}
                </>
            ) : (
                <>
                    <Tab onClick={() => handleOnClick(GQLFinanceType.openEndLeasing)} disabled={financeType === GQLFinanceType.openEndLeasing}>
                        <FormattedMessage id="leaseme.openEndLeasing" defaultMessage="Open-End-Leasing" />
                    </Tab>
                    <Tab onClick={() => handleOnClick(GQLFinanceType.closedEndLeasing)} disabled={financeType === GQLFinanceType.closedEndLeasing}>
                        <FormattedMessage id="leaseme.closedEndLeasing" defaultMessage="Closed-End-Leasing" />
                    </Tab>
                </>
            )}
        </Tabs>
    );
};

export default BoxTabs;
