import styled from "styled-components";

export const RelatedLinksWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.backgroundLightGray};
    padding: 40px 20px 20px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 40px 70px;
    }
    ${({ theme }) => theme.breakpoints.xl.mediaquery} {
        padding: 40px 140px;
    }
`;

export const LinksHeadline = styled.span`
    font-weight: 700;
    line-height: 24px;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    margin-bottom: 15px;
`;

export const LinkSection = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 75%;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        flex-basis: 45%;
    }

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        flex-basis: 15%;
    }
`;

export const Content = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        gap: 10;
    }
`;
