import styled from "styled-components";

export const FilterContainer = styled.div`
    display: flex;
    flex-direction: column;

    color: ${({ theme }) => theme.colors.white};

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        flex: 1;
        height: 160px;
    }
`;

export const FilterLabelContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
`;

export const FilterLabel = styled.span`
    font-weight: 400;
    white-space: nowrap;
    line-height: 24px;
    font-size: 16px;
`;

export const FilterControl = styled.div`
    width: 100%;
`;

export const FilterMessage = styled.p`
    font-size: 12px;
`;
