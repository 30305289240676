import styled from "styled-components";
import { Swiper } from "swiper/react";

export const Root = styled.section`
    margin: 40px 0;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin: 80px 0;
    }
`;

export const Title = styled.h2`
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    text-align: center;
    margin-bottom: 30px;
    font-size: 26px;
    line-height: 30px;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        margin-bottom: 40px;
        font-size: 32px;
        line-height: 40px;
    }
`;

export const FlickityContainer = styled.div`
    .dot {
        width: 7px;
        height: 7px;
        margin: 3px 7px;
        background: ${({ theme }) => theme.colors.gray4};

        &.is-selected {
            background: ${({ theme }) => theme.colors.porscheBankBlue};
        }
    }

    ${({ theme }) => theme.breakpoints.xl.mediaquery} {
        display: none;
    }
`;

export const MobileContainer = styled.div`
    padding: 0 20px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 0 70px;
    }
    ${({ theme }) => theme.breakpoints.xl.mediaquery} {
        display: none;
    }
`;

export const DesktopContainer = styled.div`
    display: none;

    ${({ theme }) => theme.breakpoints.xl.mediaquery} {
        display: block;
        padding: 0 140px;
    }
`;

interface CardContainerProps {
    itemCount?: number;
}

export const CardContainer = styled.div<CardContainerProps>`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        justify-content: ${({ itemCount }) => (itemCount && itemCount < 3 ? "flex-start" : "space-between")};
        gap: ${({ itemCount }) => (itemCount && itemCount < 3 ? "100px" : "20px")};
        align-items: center;
        flex-direction: row;
    }
`;

export const HeadlineText = styled.h1`
    margin-bottom: 30px;
    font-size: 26px;
    line-height: 31px;
    font-weight: 700;
    margin-top: 19px;
    text-transform: uppercase;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        margin-bottom: 40px;
        font-size: 32px;
        line-height: 42px;
    }
`;

export const HorizontalLine = styled.div`
    border-top: 3px solid ${({ theme }) => theme.colors.darkRed};
    width: 76px;
`;

export const HeadlineContainer = styled.div`
    margin: 20px 0;
    padding: 0 20px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 0 70px;
        margin: 40px 0;
    }
    ${({ theme }) => theme.breakpoints.xl.mediaquery} {
        padding: 0 140px;
    }
`;

export const SwiperSlider = styled(Swiper)`
    .swiper-wrapper {
        margin-bottom: 40px;
    }

    .swiper-pagination {
        box-sizing: border-box;
        justify-content: center;
        text-align: center;
        display: flex;
        padding: 20px 0;
        width: 100%;
        z-index: 10;
        position: absolute;
        bottom: 0;
        left: 0;

        ${({ theme }) => theme.breakpoints.xl.mediaquery} {
            display: none;
        }

        &-bullet {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 7px;
            height: 7px;
            cursor: pointer;
            background: ${({ theme }) => theme.colors.gray4};
            margin: 3px 7px;
            border-radius: 7px;
            opacity: 0.25;

            &:first-child:last-child {
                display: none;
            }

            &-active {
                background-color: ${({ theme }) => theme.colors.porscheBankBlue};
                opacity: 1;
            }
        }
    }
`;
