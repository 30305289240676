import React from "react";

import BoxTabContent from "./BoxTabContent";
import BoxTabs from "./BoxTabs";
import { ContentWrapper, FiltersRoot } from "./Searchbox.sc";

interface SearchboxProps {
    filters: React.ReactElement;
    hasEndAdornment?: boolean;
    infoIconText?: React.ReactNode;
}

const Searchbox: React.FC<SearchboxProps> = ({ filters, hasEndAdornment, infoIconText }) => {
    return (
        <ContentWrapper>
            <BoxTabs />
            <BoxTabContent hasEndAdornment={hasEndAdornment} infoIconText={infoIconText} />
            <FiltersRoot>{filters}</FiltersRoot>
        </ContentWrapper>
    );
};

export default Searchbox;
