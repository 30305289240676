import { getConfig } from "@src/config";
import DownpaymentFilter from "@src/shared/home/searchbox/filterItems/DownpaymentFilter";
import ResidualValueSelectFilter from "@src/shared/home/searchbox/filterItems/ResidualValueSelectFilter";
import RuntimeFilter from "@src/shared/home/searchbox/filterItems/RuntimeFilter";
import { getCurrencySignFromDomain } from "@src/skeleton/intlProvider/CustomIntlProvider";
import React from "react";

const Filters: React.FC = () => {
    return (
        <>
            <RuntimeFilter />
            <DownpaymentFilter
                startAdornment={getCurrencySignFromDomain(getConfig("scope").domain)}
                endAdornment={getCurrencySignFromDomain(getConfig("scope").domain)}
            />
            <ResidualValueSelectFilter />
        </>
    );
};

export default Filters;
