import * as React from "react";
import { FormattedMessage } from "react-intl";

import * as sc from "./Headline.sc";

const Headline: React.FC = () => {
    return (
        <sc.HeadlineContainer>
            <sc.HorizontalLine />
            <sc.HeadlineText>
                car4me. <FormattedMessage id="leaseme.headerText" defaultMessage="Ein eigenes Auto um jeden Preis." />
            </sc.HeadlineText>
        </sc.HeadlineContainer>
    );
};

export default Headline;
