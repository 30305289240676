import { GQLFinanceType } from "@src/graphql.apollo.generated";
import { useIntl } from "react-intl";

import { IFilter } from "./IFilter";

const RuntimesAndResidualValuesOFL: Record<number, { max: number; min: number }> = {
    24: {
        min: 25,
        max: 40,
    },
    36: {
        min: 20,
        max: 30,
    },
    48: {
        min: 15,
        max: 25,
    },
    60: {
        min: 10,
        max: 20,
    },
    72: {
        min: 10,
        max: 10,
    },
    84: {
        min: 5,
        max: 5,
    },
};

const RuntimesAndIncreasedLastInstallmentCFL: Record<number, { max: number; min: number }> = {
    12: {
        min: 0,
        max: 50,
    },
    24: {
        min: 0,
        max: 40,
    },
    36: {
        min: 0,
        max: 30,
    },
    48: {
        min: 0,
        max: 25,
    },
    60: {
        min: 0,
        max: 20,
    },
    72: {
        min: 0,
        max: 0,
    },
};

export const useResidualValueOptions = (filter: IFilter) => {
    const intl = useIntl();

    const residualValues: number[] = [];

    function generateResidualValueOptions(min: number, max: number) {
        let count = min;
        for (count === min; count <= max; count = count + 5) {
            residualValues.push(count);
        }
    }

    if (filter.financeType === GQLFinanceType.openEndLeasing && filter.financeRuntime) {
        generateResidualValueOptions(
            RuntimesAndResidualValuesOFL[filter.financeRuntime].min,
            RuntimesAndResidualValuesOFL[filter.financeRuntime].max,
        );
    } else if (filter.financeType === GQLFinanceType.closedEndLeasing && filter.financeRuntime) {
        generateResidualValueOptions(
            RuntimesAndIncreasedLastInstallmentCFL[filter.financeRuntime].min,
            RuntimesAndIncreasedLastInstallmentCFL[filter.financeRuntime].max,
        );
    }

    return residualValues.map((residualValue) => ({
        value: residualValue,
        label: intl.formatMessage(
            {
                id: "leaseme.residualValue.value",
                defaultMessage: "{residualValue} %",
            },
            {
                residualValue: intl.formatNumber(residualValue),
            },
        ),
    }));
};
