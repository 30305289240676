import { getConfig } from "@src/config";
import { getKmPerYearOptions } from "@src/shared/context/searchfilter/Options";
import useSearchFilter from "@src/shared/context/searchfilter/useSearchFilter";
import InfoIcon from "@src/shared/infoIcon/InfoIcon";
import SingleChoiceFilter from "@src/shared/searchResults/searchfilter/filter/singlechoicefilter/SingleChoiceFilter";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { FilterContainer, FilterControl, FilterLabel, FilterLabelContainer } from "./Filters.sc";

const KmPerYearFilter: React.FC = () => {
    const intl = useIntl();
    const filter = useSearchFilter();

    return (
        <FilterContainer>
            <FilterLabelContainer>
                <FilterLabel>
                    <FormattedMessage id="leaseme.perYear" defaultMessage="pro Jahr" />
                </FilterLabel>
                <InfoIcon
                    color="white"
                    infoText={intl.formatMessage({
                        id: "leaseme.home.perYear.message",
                        defaultMessage:
                            "Geben Sie hier die Kilometerzahl an, die Sie pro Jahr fahren. Schätzen Sie Ihre Fahrleistung möglichst genau ein. So kann ein realistischer Restwert kalkuliert und Nachzahlungen für Mehrkilometer bei Vertragsende vermieden werden.",
                    })}
                />
            </FilterLabelContainer>
            <FilterControl>
                <SingleChoiceFilter
                    value={filter.filter.kmPerYear}
                    options={getKmPerYearOptions(getConfig("scope").domain)}
                    onChange={(value) => filter.setFilter?.("kmPerYear", value)}
                />
            </FilterControl>
        </FilterContainer>
    );
};

export default KmPerYearFilter;
