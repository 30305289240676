import { getConfig } from "@src/config";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import InfoIcon from "../infoIcon/InfoIcon";
import FormattedCurrency from "../utils/FormattedCurrency";
import * as sc from "./PriceInfo.sc";

interface IPriceInfoProps {
    price: number | null;
    priceInfoText: string;
    alignment?: "left" | "right";
}

const PriceInfo: React.FunctionComponent<IPriceInfoProps> = ({ price, priceInfoText, alignment = "right" }) => {
    const scope = getConfig("scope");
    return (
        <sc.PriceInfoContainer>
            <sc.Price>
                <FormattedCurrency value={price} />
            </sc.Price>
            <sc.Month isLowercase={scope.domain === "car4me.porschefinance.hu"}>
                / <FormattedMessage id="leaseme.month.single" defaultMessage="Monat" />
            </sc.Month>
            <InfoIcon infoText={priceInfoText} alignment={alignment} />
        </sc.PriceInfoContainer>
    );
};

export default PriceInfo;
