import { getConfig } from "@src/config";
import { useFinanceRuntimeOptions } from "@src/shared/context/searchfilter/Options";
import useSearchFilter from "@src/shared/context/searchfilter/useSearchFilter";
import InfoIcon from "@src/shared/infoIcon/InfoIcon";
import SingleChoiceFilter from "@src/shared/searchResults/searchfilter/filter/singlechoicefilter/SingleChoiceFilter";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { FilterContainer, FilterControl, FilterLabel, FilterLabelContainer } from "./Filters.sc";

interface RuntimeFilterProps {
    hasInfoIcon?: boolean;
}

const RuntimeFilter: React.FC<RuntimeFilterProps> = ({ hasInfoIcon = false }) => {
    const filter = useSearchFilter();
    const intl = useIntl();

    const financeRuntimeOptions = useFinanceRuntimeOptions(getConfig("scope").domain);

    return (
        <FilterContainer>
            <FilterLabelContainer>
                <FilterLabel>
                    <FormattedMessage id="leaseme.runtime.label" defaultMessage="Laufzeit" />
                </FilterLabel>
                {hasInfoIcon && (
                    <InfoIcon
                        color="white"
                        infoText={intl.formatMessage({
                            id: "leaseme.duration.message",
                            defaultMessage: "Wählen Sie hier die Laufzeit des Leasing-/Kreditvertrags.",
                        })}
                    />
                )}
            </FilterLabelContainer>
            <FilterControl>
                <SingleChoiceFilter
                    value={filter.filter.financeRuntime}
                    options={financeRuntimeOptions}
                    onChange={(value) => filter.setFilter?.("financeRuntime", value)}
                />
            </FilterControl>
        </FilterContainer>
    );
};

export default RuntimeFilter;
