import styled from "styled-components";

export const HeadlineText = styled.h1`
    margin-bottom: 30px;
    font-size: 26px;
    line-height: 30px;
    font-weight: 500;
    margin-top: 19px;
    text-transform: uppercase;
    font-weight: 700;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        margin-bottom: 40px;
        font-size: 32px;
        line-height: 40px;
    }
`;

export const HorizontalLine = styled.div`
    border-top: 3px solid ${({ theme }) => theme.colors.darkRed};
    width: 76px;
`;

export const HeadlineContainer = styled.div`
    margin: 40px 0;
    padding: 0 20px;
    color: ${({ theme }) => theme.colors.white};

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 0 70px;
        margin: 80px 0;
    }
    ${({ theme }) => theme.breakpoints.xl.mediaquery} {
        padding: 0 140px;
    }
`;
