import { getConfig } from "@src/config";
import { GQLFinanceType } from "@src/graphql.apollo.generated";
import useSearchFilter from "@src/shared/context/searchfilter/useSearchFilter";
import DownpaymentFilter from "@src/shared/home/searchbox/filterItems/DownpaymentFilter";
import KmPerYearFilter from "@src/shared/home/searchbox/filterItems/KmPerYearFilter";
import RemainingCreditFilter from "@src/shared/home/searchbox/filterItems/RemainingCreditFilter";
import RuntimeFilter from "@src/shared/home/searchbox/filterItems/RuntimeFilter";
import { getCurrencySignFromDomain } from "@src/skeleton/intlProvider/CustomIntlProvider";
import React from "react";

const Filters: React.FC = () => {
    const filter = useSearchFilter();

    return (
        <>
            <RuntimeFilter />
            <DownpaymentFilter startAdornment={getCurrencySignFromDomain(getConfig("scope").domain)} />
            {filter.filter.financeType === GQLFinanceType.leasing ? <KmPerYearFilter /> : <RemainingCreditFilter />}
        </>
    );
};

export default Filters;
