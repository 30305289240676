import styled from "styled-components";

interface RootProps {
    headerImageUrl?: string;
}

export const Root = styled.div<RootProps>`
    width: 100%;
    background: ${({ headerImageUrl, theme }) => (headerImageUrl ? `url(${headerImageUrl})` : theme.colors.porscheBankBlue)};

    ${({ headerImageUrl }) => headerImageUrl && "background-color: rgba(0, 0, 0, 0.3)"};

    background-size: cover;
    background-blend-mode: darken;
    margin-top: -50px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin-top: -140px;
    }
`;

export const RootContent = styled.div`
    padding: 30px 0;
    width: 100%;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 100px 0 0;
        z-index: 1;
    }
`;

export const RootTriangle = styled.div`
    background-color: ${({ theme }) => theme.colors.porscheBankBlue};
    position: relative;
    margin-left: -10%;
    height: 50px;
    width: 120%;
    bottom: 40px;
    transform: rotate(-1.5deg);
    z-index: -1;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        display: block;
        bottom: 25px;
    }

    ${({ theme }) => theme.breakpoints.xxl.mediaquery} {
        transform: rotate(-0.5deg);
    }
`;

export const RootWrapper = styled.div<RootProps>`
    ${({ headerImageUrl }) => !headerImageUrl && "margin-bottom: -40px;"};
`;
