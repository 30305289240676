import { useLocation } from "@reach/router";
import { getConfig } from "@src/config";
import { GQLFinanceType, GQLVehicleCardVehicleFragment } from "@src/graphql.apollo.generated";
import Images from "@src/shared/assets/images/images";
import useSearchFilter from "@src/shared/context/searchfilter/useSearchFilter";
import PriceInfo from "@src/shared/priceInfo/PriceInfo";
import FormattedCurrency from "@src/shared/utils/FormattedCurrency";
import { generateVehicleDetailUrl } from "@src/shared/vehicleDetail/generateVehicleDetailUrl";
import * as React from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";

import { Content, DetailsLink, ImageWrapper, LinkWrapper, Root, Stat, StatLabel, Stats, StatValue, Title, VehicleImage } from "./VehicleCard.sc";

interface IVehicleCardProps {
    vehicle: GQLVehicleCardVehicleFragment;
    priceInfoAlignment?: "left" | "right";
}

export const VehicleCard: React.FunctionComponent<IVehicleCardProps> = ({ vehicle, priceInfoAlignment = "right" }) => {
    const intl = useIntl();
    const location = useLocation();

    const priceInfoText = intl.formatMessage({
        id: "leaseme.priceInfo.message",
        defaultMessage: "Mehr Informationen zum Finanzierungsangebot auf der Detailseite.",
    });

    const { filter } = useSearchFilter();
    const scope = getConfig("scope");

    return (
        <Root>
            <LinkWrapper to={generateVehicleDetailUrl(vehicle, filter, location)}>
                <ImageWrapper>
                    <VehicleImage
                        alt={intl.formatMessage({
                            id: "leaseme.relatedLinks.card.image",
                            defaultMessage: "Fahrzeug Vorschaubild",
                        })}
                        src={vehicle.images?.[0]?.uri ?? Images.CarAvatar}
                        loadLazy={true}
                    />
                </ImageWrapper>
                <Content>
                    <Title isUppercase={scope.domain === "car4me.porschefinance.hu"}>{vehicle.model}</Title>
                    <PriceInfo price={vehicle.rate} priceInfoText={priceInfoText} alignment={priceInfoAlignment} />
                    <Stats>
                        <Stat>
                            <StatLabel>
                                <FormattedMessage id="leaseme.runtime.label" defaultMessage="Laufzeit" />
                            </StatLabel>
                            <StatValue>
                                <FormattedMessage
                                    id="leaseme.runtime.value"
                                    defaultMessage="{financeRuntime} Monate"
                                    values={{
                                        financeRuntime: intl.formatNumber(filter.financeRuntime),
                                    }}
                                />
                            </StatValue>
                        </Stat>
                        {filter.financeType === GQLFinanceType.leasing &&
                            scope.domain !== "car4me.si" &&
                            scope.domain !== "car4me.porschefinance.hu" && (
                                <Stat>
                                    <StatLabel>
                                        <FormattedMessage id="leaseme.kmPerYear.label" defaultMessage="km/Jahr" />
                                    </StatLabel>
                                    <StatValue>
                                        <FormattedNumber value={filter.kmPerYear} /> <FormattedMessage id="leaseme.km" defaultMessage="km" />
                                    </StatValue>
                                </Stat>
                            )}
                        {filter.financeType === GQLFinanceType.closedEndLeasing && scope.domain === "car4me.porschefinance.hu" && (
                            <Stat>
                                <StatLabel>
                                    <FormattedMessage id="leaseme.increasedI.label" defaultMessage="ErhöhteR." />
                                </StatLabel>
                                <StatValue>
                                    {/* TODO: replace later remainingCredit with correct values when available */}
                                    <FormattedNumber value={filter.remainingCredit || 0} />
                                    <FormattedMessage id="leaseme.percent" defaultMessage="%" />
                                </StatValue>
                            </Stat>
                        )}
                        {filter.financeType === GQLFinanceType.openEndLeasing && scope.domain === "car4me.porschefinance.hu" && (
                            <Stat>
                                <StatLabel>
                                    <FormattedMessage id="leaseme.remCredit.label" defaultMessage="Resid. Kredit" />
                                </StatLabel>
                                <StatValue>
                                    {/* TODO: replace later remainingCredit with correct values when available */}
                                    <FormattedNumber value={filter.remainingCredit || 0} />
                                    <FormattedMessage id="leaseme.percent" defaultMessage="%" />
                                </StatValue>
                            </Stat>
                        )}
                        {filter.financeType === GQLFinanceType.credit && (
                            <Stat>
                                <StatLabel>
                                    <FormattedMessage id="leaseme.remainingCredit.label" defaultMessage="Restkredit" />
                                </StatLabel>
                                <StatValue>{filter.remainingCredit}%</StatValue>
                            </Stat>
                        )}
                        <Stat>
                            <StatLabel>
                                <FormattedMessage id="leaseme.downPayment.label" defaultMessage="Eigenleistung" />
                            </StatLabel>
                            <StatValue>
                                <FormattedCurrency value={vehicle.downPayment} />
                            </StatValue>
                        </Stat>
                    </Stats>
                    <DetailsLink>
                        <FormattedMessage
                            id="leaseme.toOffer"
                            defaultMessage={`${scope.domain === "car4me.porschefinance.hu" ? "Fahrzeugdetails" : "Zum Angebot"}`}
                        />
                    </DetailsLink>
                </Content>
            </LinkWrapper>
        </Root>
    );
};
