import "swiper/css";

import { getConfig } from "@src/config";
import useDisplayName from "@src/shared/context/displayname/useDisplayName";
import useSearchFilter from "@src/shared/context/searchfilter/useSearchFilter";
import useSearchResult from "@src/shared/context/searchresult/useSearchResult";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import SwiperCore, { Pagination } from "swiper";
import { SwiperSlide } from "swiper/react";

import {
    CardContainer,
    DesktopContainer,
    HeadlineContainer,
    HeadlineText,
    HorizontalLine,
    MobileContainer,
    Root,
    SwiperSlider,
} from "./LatestOffers.sc";
import { VehicleCard } from "./VehicleCard";

SwiperCore.use([Pagination]);

export const LatestOffers: React.FunctionComponent = () => {
    const { filter } = useSearchFilter();
    const { getDisplayName } = useDisplayName();

    const searchResult = useSearchResult();
    const vehicles = searchResult.vehicles?.slice(0, 4);

    const disclaimer = getConfig("scope").domain === "car4me.porschefinance.hu" && (
        <DisclaimerTextWrapper>
            <FormattedMessage id="leaseme.disclaimerText" defaultMessage="HU Legal Disclaimer für Startseite" />
        </DisclaimerTextWrapper>
    );

    return vehicles?.length && vehicles.length > 0 ? (
        <Root>
            <HeadlineContainer>
                <HorizontalLine />
                <HeadlineText>
                    <FormattedMessage
                        id="leaseme.latestOffers.title"
                        defaultMessage="Neue {financeType}-Angebote"
                        values={{
                            financeType: getDisplayName("financeType", filter.financeType),
                        }}
                    />
                </HeadlineText>
            </HeadlineContainer>

            <DesktopContainer>
                <CardContainer itemCount={vehicles?.length}>
                    {vehicles?.map((vehicle, idx) => (
                        <VehicleCard key={vehicle.vehicleId!} vehicle={vehicle} priceInfoAlignment={idx === 0 ? "left" : "right"} />
                    ))}
                </CardContainer>
                {disclaimer}
            </DesktopContainer>

            <MobileContainer>
                {vehicles?.length && vehicles.length > 2 ? (
                    <SwiperSlider
                        spaceBetween={30}
                        slidesPerView={"auto"}
                        loop={true}
                        pagination={{ clickable: true }}
                        shortSwipes={false}
                        longSwipesMs={100}
                        longSwipesRatio={0.1}
                        allowTouchMove={true}
                        breakpoints={{
                            768: {
                                spaceBetween: 40,
                            },
                        }}
                    >
                        {vehicles?.map((vehicle) => (
                            <SwiperSlide key={vehicle.vehicleId!} style={{ maxWidth: "320px" }}>
                                <VehicleCard vehicle={vehicle} />
                            </SwiperSlide>
                        ))}
                    </SwiperSlider>
                ) : (
                    <CardContainer itemCount={vehicles?.length}>
                        {vehicles?.map((vehicle, idx) => (
                            <VehicleCard key={vehicle.vehicleId!} vehicle={vehicle} priceInfoAlignment={idx === 0 ? "left" : "right"} />
                        ))}
                    </CardContainer>
                )}
                {disclaimer}
            </MobileContainer>
        </Root>
    ) : null;
};

const DisclaimerTextWrapper = styled.div`
    margin-top: 40px;
`;
