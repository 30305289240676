import * as ArrowRight from "@src/shared/assets/images/arrow.inline.svg";
import * as ChevronDown from "@src/shared/assets/images/chevron_down.inline.svg";
import { Link } from "gatsby";
import styled from "styled-components";

export const LinkArrow = styled(ArrowRight)`
    display: block;
    width: 12px;
    height: 12px;
    margin-right: 5px;
`;

export const LinkItem = styled(Link)`
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    line-height: 20px;
    font-size: 16px;
    text-decoration: none;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
`;

export const DesktopList = styled.div`
    display: none;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: block;
    }
`;

export const MobileList = styled.div`
    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: none;
    }
`;

export const ExpandButton = styled.div`
    margin-top: 25px;
    padding-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    border-top: 1px solid ${({ theme }) => theme.colors.borderLightGray};
`;

export const ButtonText = styled.button`
    width: 200px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    font-size: 16px;
    line-height: 22px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ShowMoreChevron = styled(ChevronDown)`
    width: 12px;
    height: 12px;
    margin-right: 10px;
`;

export const ShowLessChevron = styled(ShowMoreChevron)`
    transform: rotate(180deg);
`;
