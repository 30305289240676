import FiltersAT from "@src/AT/home/Filters";
import HeadlineAT from "@src/AT/home/headline/Headline";
import FiltersBG from "@src/BG/home/Filters";
import HeadlineBG from "@src/BG/home/headline/Headline";
import { getConfig } from "@src/config";
import { GQLVehicleSearchResultsQuery } from "@src/graphql.apollo.generated";
import { GQLHomePageQuery, GQLLeaseMe_Page, GQLLeaseMe_PageTreeNodeScopeInput } from "@src/graphql.gatsby.generated";
import FiltersHU from "@src/HU/home/Filters";
import HeadlineHU from "@src/HU/home/headline/Headline";
import FiltersRO from "@src/RO/home/Filters";
import HeadlineRO from "@src/RO/home/headline/Headline";
import { PageContentBlock } from "@src/shared/cms/renderBlock";
import { LatestOffers } from "@src/shared/home/latestOffers/LatestOffers";
import RelatedLinks from "@src/shared/home/relatedLinks/RelatedLinks";
import Searchbox from "@src/shared/home/searchbox/Searchbox";
import SEO from "@src/shared/seo/SEO";
import FiltersSI from "@src/SI/home/Filters";
import HeadlineSI from "@src/SI/home/headline/Headline";
import { graphql, PageProps } from "gatsby";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import Master from "../layout/Master";
import { Root, RootContent, RootTriangle, RootWrapper } from "./HomePage.template.sc";

export interface HomePageContext {
    initialData: GQLVehicleSearchResultsQuery;
    scope: GQLLeaseMe_PageTreeNodeScopeInput;
}

function getContentData() {
    let filters: React.ReactElement;
    let headline: React.ReactElement;
    switch (getConfig("scope").domain) {
        case "car4me.bg":
            filters = <FiltersBG />;
            headline = <HeadlineBG />;
            break;
        case "car4me.ro":
            filters = <FiltersRO />;
            headline = <HeadlineRO />;
            break;
        case "car4me.si":
            filters = <FiltersSI />;
            headline = <HeadlineSI />;
            break;
        case "car4me.porschefinance.hu":
            filters = <FiltersHU />;
            headline = <HeadlineHU />;
            break;
        default:
            filters = <FiltersAT />;
            headline = <HeadlineAT />;
            break;
    }
    return { filters, headline };
}

const HomePageTemplate: React.FunctionComponent<PageProps<GQLHomePageQuery, HomePageContext>> = ({ data }) => {
    let content;
    let seo;
    let stage = undefined;

    if (data.leaseme.pageTreeNodeByPath?.document) {
        stage = (data?.leaseme.pageTreeNodeByPath?.document as GQLLeaseMe_Page).stage;
        content = (data.leaseme.pageTreeNodeByPath.document as GQLLeaseMe_Page).content;
        seo = (data.leaseme.pageTreeNodeByPath.document as GQLLeaseMe_Page).seo;
    }

    const contentData = getContentData();

    return (
        <Master fullWidth>
            <SEO
                title={seo?.htmlTitle ?? ""}
                description={seo?.metaDescription ?? ""}
                openGraphDescription={seo?.openGraphDescription}
                openGraphTitle={seo?.openGraphTitle}
                openGraphImage={seo?.openGraphImage}
            />
            <RootWrapper headerImageUrl={stage?.damFile?.fileUrl}>
                <Root headerImageUrl={stage?.damFile?.fileUrl}>
                    <RootContent>
                        {contentData.headline}
                        <Searchbox
                            filters={contentData.filters}
                            hasEndAdornment={getConfig("scope").domain === "car4me.bg"}
                            infoIconText={
                                getConfig("scope").domain === "car4me.ro" ? (
                                    <FormattedMessage
                                        id="leaseme.ro.rate.infoIcon.text"
                                        defaultMessage="Der angegebene Betrag umfasst nur die Finanzierung. Der Abschluss einer CASCO-Versicherung ist obligatorisch und wird zu diesen Kosten hinzugerechnet. Weitere Einzelheiten finden Sie auf der nächsten Seite."
                                    />
                                ) : undefined
                            }
                        />
                    </RootContent>
                </Root>
                {!stage?.damFile?.fileUrl && <RootTriangle />}
            </RootWrapper>
            {content && <PageContentBlock data={content} />}
            <LatestOffers />
            <RelatedLinks />
        </Master>
    );
};

export default HomePageTemplate;

export const query = graphql`
    query HomePage($leasemeScope: LeaseMe_ContentScopeInput!, $scope: LeaseMe_PageTreeNodeScopeInput!) {
        leaseme {
            allVehicles(scope: $leasemeScope) {
                facets {
                    ...AllFacets
                }
            }
            pageTreeNodeByPath(scope: $scope, path: "/") {
                id
                document {
                    ... on LeaseMe_Page {
                        content
                        seo
                        stage
                    }
                }
            }
        }
    }
`;
