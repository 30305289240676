import DownpaymentFilter from "@src/shared/home/searchbox/filterItems/DownpaymentFilter";
import KmPerYearFilter from "@src/shared/home/searchbox/filterItems/KmPerYearFilter";
import RuntimeFilter from "@src/shared/home/searchbox/filterItems/RuntimeFilter";
import React from "react";

const Filters: React.FC = () => {
    return (
        <>
            <RuntimeFilter />
            <DownpaymentFilter hasMinMaxCheck disableInfoIcon />
            <KmPerYearFilter />
        </>
    );
};

export default Filters;
