import useSearchFilter from "@src/shared/context/searchfilter/useSearchFilter";
import TextInputFilter from "@src/shared/searchResults/searchfilter/filter/inputfilter/TextInputFilter";
import React from "react";
import { FormattedMessage } from "react-intl";

import { FilterContainer, FilterControl, FilterLabel, FilterLabelContainer } from "./Filters.sc";

const RemainingCreditFilter: React.FC = () => {
    const { filter } = useSearchFilter();

    return (
        <FilterContainer>
            <FilterLabelContainer>
                <FilterLabel>
                    <FormattedMessage id="leaseme.remainingCredit.label" defaultMessage="Restkredit" />
                </FilterLabel>
            </FilterLabelContainer>
            <FilterControl>
                <TextInputFilter value={`${filter.remainingCredit} %`} disabled />
            </FilterControl>
        </FilterContainer>
    );
};

export default RemainingCreditFilter;
