import styled, { css } from "styled-components";

export const Price = styled.span`
    margin-right: 4px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    font-size: 23px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 30px;
`;

export const Month = styled.span<{ isLowercase: boolean }>`
    color: ${({ theme }) => theme.colors.typo};
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 20px;
    align-self: flex-end;
    margin-bottom: 3px;

    ${({ isLowercase }) =>
        isLowercase &&
        css`
            text-transform: lowercase;
        `}
`;

export const PriceInfoContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;
