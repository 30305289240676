import styled from "styled-components";

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    box-sizing: content-box;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin: 0 auto;
        max-width: 900px;
        padding: 0 70px;
    }
    ${({ theme }) => theme.breakpoints.xl.mediaquery} {
        padding: 0 140px;
    }
`;

export const FiltersRoot = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin: 20px 0;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        justify-content: space-between;
        flex-direction: row;
    }
`;
