import * as React from "react";

import * as sc from "./InputFilter.sc";

interface IInputFilterProps {
    value: string;
    onChange?: (value: string | null) => void;
    startAdornment?: string;
    endAdornment?: string;
    variant?: "default" | "dark";
    size?: "small" | "default";
    disabled?: boolean;
    maxLength?: number;
    defaultValue?: number;
    startAdornmentWeight?: "default" | "bold";
    startAdornmentPadding?: number;
    endAdornmentWeight?: "default" | "bold";
    endAdornmentPadding?: number;
}

const TextInputFilter: React.FC<IInputFilterProps> = ({
    value,
    onChange,
    startAdornment,
    endAdornment,
    variant,
    size = "default",
    disabled,
    maxLength = 10,
    defaultValue,
    startAdornmentWeight = "default",
    startAdornmentPadding = 0,
    endAdornmentWeight = "default",
    endAdornmentPadding = 0,
}) => {
    const [internalValue, setInternalValue] = React.useState(value);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInternalValue(event.currentTarget.value);
    };

    const handleBlur = () => {
        if (defaultValue !== undefined) {
            setInternalValue(internalValue);
            onChange?.(internalValue);
        } else {
            onChange?.(internalValue);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            handleBlur();
        }
    };

    const maxLengthCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length > event.target.maxLength) {
            event.target.value = event.target.value.slice(0, event.target.maxLength);
        }
    };

    return (
        <sc.Root
            startAdornment={startAdornment}
            endAdornment={endAdornment}
            variant={variant}
            size={size}
            startAdornmentWeight={startAdornmentWeight}
            startAdornmentPadding={startAdornmentPadding}
            endAdornmentWeight={endAdornmentWeight}
            endAdornmentPadding={endAdornmentPadding}
        >
            <sc.Input
                type={"text"}
                value={internalValue}
                onBlur={() => {
                    handleBlur();
                }}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                hasStartAdornment={!!startAdornment}
                hasEndAdornment={!!endAdornment}
                endAdornment={endAdornment ? endAdornment : ""}
                variant={variant}
                disabled={disabled}
                maxLength={maxLength}
                onInput={maxLengthCheck}
                startAdornmentPadding={startAdornmentPadding}
                endAdornmentPadding={endAdornmentPadding}
            />
        </sc.Root>
    );
};
export default TextInputFilter;
