import * as ArrowRight from "@src/shared/assets/images/arrow.inline.svg";
import { Link } from "gatsby";
import styled from "styled-components";

export const TabContent = styled.div`
    border-top-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    overflow: hidden;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: flex;
        border-top-left-radius: 3px;
    }
`;

export const SliderContainer = styled.div`
    background-color: white;
    border: 1px solid ${({ theme }) => theme.colors.borderLightGray};
    padding: 20px;
    width: 100%;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        flex: 1;
        max-width: 600px;
    }
    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        padding: 20px 40px;
    }
`;

export const ButtonFilterContainer = styled.div`
    padding: 12px 60px;
    width: 100%;
    display: flex;
`;

export const ResultsLink = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    border: 1px solid ${({ theme }) => theme.colors.borderLightGray};
    text-decoration: none;
    padding: 0 20px;
    height: 80px;
    box-sizing: border-box;
    font-size: 28px;
    line-height: 36px;
    font-weight: 600;
    width: 100%;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        font-size: 28px;
        height: auto;
        max-width: 300px;
    }
`;

export const LinkArrow = styled(ArrowRight)`
    width: 48px;
    height: 38px;
    display: block;
    margin-right: 16px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        width: 30px;
        height: 28px;
    }

    path {
        stroke: ${({ theme }) => theme.colors.porscheBankBlue};
        stroke-width: 25px;
    }
`;

export const LoadingSpinnerWrapper = styled.div`
    position: absolute;
`;
