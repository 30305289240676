import { GQLFinanceType } from "@src/graphql.apollo.generated";
import { useResidualValueOptions } from "@src/shared/context/searchfilter/ResidualValueOptions";
import useSearchFilter from "@src/shared/context/searchfilter/useSearchFilter";
import InfoIcon from "@src/shared/infoIcon/InfoIcon";
import SingleChoiceFilter from "@src/shared/searchResults/searchfilter/filter/singlechoicefilter/SingleChoiceFilter";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { FilterContainer, FilterControl, FilterLabel, FilterLabelContainer } from "./Filters.sc";

const ResidualValueSelectFilter: React.FC = () => {
    const intl = useIntl();
    const filter = useSearchFilter();

    const residualValueOptions = useResidualValueOptions(filter.filter);

    return (
        <FilterContainer>
            <FilterLabelContainer>
                <FilterLabel>
                    {filter.filter.financeType === GQLFinanceType.openEndLeasing ? (
                        <FormattedMessage id="leaseme.residualValue.label" defaultMessage="Restwert" />
                    ) : (
                        <FormattedMessage id="leaseme.increasedI.label" defaultMessage="ErhöhteR." />
                    )}
                </FilterLabel>
                <InfoIcon
                    color="white"
                    infoText={
                        filter.filter.financeType === GQLFinanceType.openEndLeasing
                            ? intl.formatMessage({
                                  id: "leaseme.residualValueOfl.message",
                                  defaultMessage: "Restwert InfoIcon Nachricht HU",
                              })
                            : intl.formatMessage({
                                  id: "leaseme.increasedI.message",
                                  defaultMessage: "ErhöhteR. InfoIcon Nachricht HU",
                              })
                    }
                />
            </FilterLabelContainer>
            <FilterControl>
                <SingleChoiceFilter
                    value={filter.filter.remainingCredit}
                    options={residualValueOptions}
                    onChange={(value) => {
                        filter.setFilter?.("remainingCredit", value);
                    }}
                />
            </FilterControl>
        </FilterContainer>
    );
};

export default ResidualValueSelectFilter;
