import * as Car4meLogoBG from "@src/BG/assets/images/car_4_me_white.inline.svg";
import styled from "styled-components";

export const HeadlineText = styled.h1`
    font-size: 26px;
    line-height: 30px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 6px;
    margin-top: 20px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin: 0;
    }

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        font-size: 32px;
        line-height: 40px;
    }
`;

export const HorizontalLine = styled.div`
    border-top: 3px solid ${({ theme }) => theme.colors.darkRed};
    width: 76px;
`;

export const HeadlineContainer = styled.div`
    margin: 40px 0;
    padding: 0 20px;
    color: ${({ theme }) => theme.colors.white};

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 0 70px;
        margin: 80px 0;
    }
    ${({ theme }) => theme.breakpoints.xl.mediaquery} {
        padding: 0 140px;
    }
`;

export const HeadlineWrapper = styled.div`
    display: block;
    margin-bottom: 30px;
    margin-top: 19px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
    }
`;

export const HeadlineLogo = styled.h1`
    margin: 0;
    font-size: 32px;
    line-height: 40px;
    margin-right: 20px;
    padding-top: 15px;
`;

export const TitleMe = styled.span`
    margin-left: 6px;
    &::after {
        content: " ";
        display: block;
        position: relative;
        top: -8px;
        left: 67px;
        width: 47px;
        height: 6px;
        border-right: 4px solid white;
        border-bottom: 4px solid white;
        border-left: 4px solid white;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
    }
`;

export const StyledCar4meLogoBG = styled(Car4meLogoBG)`
    height: 40px;
    width: auto;
`;
