import styled from "styled-components";

export const Tabs = styled.div`
    display: inline-flex;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    overflow: hidden;
`;

export const Tab = styled.button`
    border: 1px solid ${({ theme }) => theme.colors.borderLightGray};
    background-color: white;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    font-size: 18px;
    padding: 15px 0;
    flex-basis: 50%;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        flex-basis: auto;
        width: 200px;
    }

    :disabled {
        font-weight: bold;
        border-bottom: 2px solid ${({ theme }) => theme.colors.porscheBankBlue};
    }
`;
