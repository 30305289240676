import { getConfig } from "@src/config";
import { GQLFinanceType } from "@src/graphql.apollo.generated";
import useSearchFilter from "@src/shared/context/searchfilter/useSearchFilter";
import DownpaymentFilter from "@src/shared/home/searchbox/filterItems/DownpaymentFilter";
import ResidualValueFilter from "@src/shared/home/searchbox/filterItems/ResidualValueFilter";
import RuntimeFilter from "@src/shared/home/searchbox/filterItems/RuntimeFilter";
import { getCurrencySignFromDomain } from "@src/skeleton/intlProvider/CustomIntlProvider";
import React from "react";
import { FormattedMessage } from "react-intl";

const Filters: React.FC = () => {
    const filter = useSearchFilter();

    return (
        <>
            <RuntimeFilter hasInfoIcon />
            <DownpaymentFilter
                hasMinMaxCheck
                infoIconText={
                    <FormattedMessage
                        id="leaseme.ro.downPayment.message"
                        defaultMessage="Geben Sie den Betrag des Vorschusses an, über den Sie verfügen."
                    />
                }
                startAdornment={getCurrencySignFromDomain(getConfig("scope").domain)}
            />
            {filter.filter.financeType === GQLFinanceType.leasing && <ResidualValueFilter />}
        </>
    );
};

export default Filters;
