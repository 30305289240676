import InfoIcon from "@src/shared/infoIcon/InfoIcon";
import InputFilter from "@src/shared/searchResults/searchfilter/filter/inputfilter/InputFilter";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { FilterContainer, FilterControl, FilterLabel, FilterLabelContainer } from "./Filters.sc";

const ResidualValueFilter: React.FC = () => {
    const intl = useIntl();

    return (
        <FilterContainer>
            <FilterLabelContainer>
                <FilterLabel>
                    <FormattedMessage id="leaseme.residualValue.label" defaultMessage="Restwert" />
                </FilterLabel>
                <InfoIcon
                    color="white"
                    infoText={intl.formatMessage({
                        id: "leaseme.residualValue.message",
                        defaultMessage:
                            "Als Restwert gilt die letzte Rechnung des Leasingvertrags. Der Restwert hat einen direkten Einfluss auf die monatliche Rate.",
                    })}
                />
            </FilterLabelContainer>
            <FilterControl>
                <InputFilter value="20" endAdornment="%" disabled />
            </FilterControl>
        </FilterContainer>
    );
};

export default ResidualValueFilter;
