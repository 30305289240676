import { GQLLeaseMe_DocumentFacet, Maybe } from "@src/graphql.gatsby.generated";
import useSearchResult from "@src/shared/context/searchresult/useSearchResult";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { Content, LinkSection, LinksHeadline, RelatedLinksWrapper } from "./RelatedLinks.sc";
import RelatedLinksList from "./RelatedLinksList";

const RelatedLinks: React.FunctionComponent = () => {
    const { facets } = useSearchResult();

    const modelGroups = sortAndSlice(facets?.modelGroups) || [];
    const brands = sortAndSlice(facets?.brands) || [];
    const bodyTypes = sortAndSlice(facets?.bodyTypes) || [];
    const provinces = sortAndSlice(facets?.provinces) || [];

    return (
        <RelatedLinksWrapper>
            <Content>
                {modelGroups.length > 0 && (
                    <LinkSection>
                        <LinksHeadline>
                            <FormattedMessage id="leaseme.relatedLinks.models" defaultMessage="Beliebte Modelle" />
                        </LinksHeadline>
                        <RelatedLinksList itemList={modelGroups} itemType="modelGroups" />
                    </LinkSection>
                )}
                {brands.length > 0 && (
                    <LinkSection>
                        <LinksHeadline>
                            <FormattedMessage id="leaseme.relatedLinks.brands" defaultMessage="Unsere Top-Marken" />
                        </LinksHeadline>
                        <RelatedLinksList itemList={brands} itemType="brands" />
                    </LinkSection>
                )}
                {bodyTypes.length > 0 && (
                    <LinkSection>
                        <LinksHeadline>
                            <FormattedMessage id="leaseme.bodyType.plural" defaultMessage="Karosserieformen" />
                        </LinksHeadline>
                        <RelatedLinksList itemList={bodyTypes} itemType="bodyTypes" />
                    </LinkSection>
                )}
                {provinces.length > 0 && (
                    <LinkSection>
                        <LinksHeadline>
                            <FormattedMessage id="leaseme.relatedLinks.regions" defaultMessage="Regionen" />
                        </LinksHeadline>
                        <RelatedLinksList itemList={provinces} itemType="provinces" />
                    </LinkSection>
                )}
            </Content>
        </RelatedLinksWrapper>
    );
};

export default RelatedLinks;

function sortAndSlice(facetArr?: Maybe<Pick<GQLLeaseMe_DocumentFacet, "name" | "slug" | "count">[]>) {
    return facetArr
        ?.filter((item) => item.slug.length > 0)
        .sort((a, b) => b.count - a.count)
        .slice(0, 10);
}
